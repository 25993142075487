import { Language } from 'models/diam';
import { defaultLanguageCode } from 'utils/constants';

/**
 * Finding the first language in the Navigator list that
 * matches the supported languages. Converting Navigator
 * codes to ensure that the return value is of format matching
 * available language.
 */
export function determineLanguage(
  supportedLangusages: Language[],
  providedLanguage?: string | null,
  agentLangCodes = window.navigator.languages,
  defaultLangCode = defaultLanguageCode
): string {
  if (
    providedLanguage &&
    supportedLangusages.some(
      (language) => language.langCode === providedLanguage
    )
  ) {
    return providedLanguage;
  }

  if (!Array.isArray(agentLangCodes)) {
    return defaultLanguageCode;
  }

  const found = agentLangCodes.find((lang) => {
    // Splitting possible lang values like en-US to get an isolated language code
    const [isoLanguage] = lang.split('-');

    return supportedLangusages.some(
      (language) => language.langCode === isoLanguage
    );
  });

  if (!found) {
    return defaultLangCode;
  }

  return found.split('-')[0];
}
